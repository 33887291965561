<script setup>
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  isPremium: {
    type: Boolean,
    default: false,
  },
});

const getCardLink = computed(() => (props.isPremium ? "/vip-club" : "/login"));
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
<template>
  <div class="relative sm:w-[320px] md:w-[355px]">
    <div class="text-white w-full rounded-t-md h-24" :class="isPremium
      ? 'bg-brand text-center'
      : 'bg-gradient-to-r from-20% from-[#E72B6F] to-[#1450A0] to-80% text-center flex justify-center items-center'
      ">
      <div v-if="!isPremium" class="font-extrabold text-base lg:text-xl leading-5">
        {{ t('home.advantagesCards.familyCard.title') }}
      </div>
      <div v-else class="pt-7">
        <h1 class="font-extrabold text-base lg:text-xl leading-5">
          {{ t('home.advantagesCards.familyVip.title') }}
        </h1>
        <h3 class="font-bold text-xs md:text-sm leading-4 pt-2 text-white">
          {{ t('home.advantagesCards.familyVip.subtitle') }}
        </h3>
      </div>
    </div>
    <div class="relative">
      <div class="hidden lg:block w-full py-2.5 mb-px bg-[#F5F6F1]"></div>
      <template v-for="(item, i) in items" :key="i">
        <div class="flex items-center w-full lg:px-4 py-2.5 mb-px bg-[#F5F6F1]" :class="!isPremium
      ? 'gap-2 px-3 sm:gap-4 sm:px-6'
      : 'gap-3 px-2 sm:px-5 sm:gap-3'
      ">
          <nuxt-img v-if="item.available" src="images/check.png" alt="check-icon" format="webp" class="w-4 h-3" />
          <nuxt-img v-else src="images/close-gray.svg" format="webp" alt="x-icon" class="size-4 blur-sm" />
          <span class="text-sm leading-4 lg:text-base lg:leading-5" :class="[isPremium ? 'text-brand' : 'text-cerise-red-600', item.available
      ? 'font-bold'
      : 'text-brand-gray font-normal', !item.available ? 'blur-sm text-brand-gray' : '']">
            {{ item.title }}</span>
        </div>
      </template>
      <div class="text-center text-xl rounded-b-md pb-6 font-bold bg-[#F5F6F1]">
        <div v-if="!isPremium" class="text-cerise-red-600 uppercase font-extrabold text-[22px] leading-9">
          <div class="pt-[22px]">{{ t('home.advantagesCards.familyCard.closingNote') }}</div>
        </div>
        <div v-else class="font-extrabold text-[22px] leading-9">
          <h1 class="font-extrabold text-brand pt-[22px]">{{
      t('home.advantagesCards.familyVip.closingNote')
    }}
          </h1>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-6">
      <nuxt-link :to="isPremium ? getCardLink : '/hazte-socio'" target="_self" class="relative">
        <button class="px-6 py-3 leading-5 font-semibold  text-white rounded-md z-10 w-full"
          :class="!isPremium ? 'bg-gradient-to-r from-20% from-[#E72B6F] to-[#1450A0] to-80%' : 'bg-brand'">
          {{ !isPremium ? t('buttons.register') : t('buttons.buyVipCard') }}
        </button>
      </nuxt-link>
    </div>
  </div>
</template>
