<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();

</script>
<template>
  <div>
    <div class="relative">
      <nuxt-img src="images/home-banner-d.png" alt="image-card" format="png" loading="lazy" preload
        :placeholder="[1200, 400]" class="rounded-tl-lg rounded-tr-lg w-full h-auto hidden md:block" />
      <nuxt-img src="images/home-banner-m.png" loading="lazy" format="webp" preload :placeholder="[400, 600]"
        class="w-full rounded-tl-lg rounded-tr-lg md:hidden" />
    </div>
    <div class="text-center my-6 md:my-10">
      <h1 class="text-chambray-600 font-extrabold text-lg md:text-[40px] leading-normal">
        {{ t('home.mainBanner.title') }}
      </h1>
      <p class="text-chambray-600 font-extrabold text-lg md:text-[40px] leading-normal">
        {{ t('home.mainBanner.content') }}
      </p>
    </div>
  </div>
</template>
