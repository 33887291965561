<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { useLoginPopUp } from "@/stores/login-popup-visibility";

const store = useLoginPopUp();
const authenticated = useCookie('authenticated')
const items = [
    {
        label: t('home.faqs.items.itemOne.label'),
        content: t('home.faqs.items.itemOne.content'),
        defaultOpen: true,
        slot: 'item-1'
    },
    {
        label: t('home.faqs.items.itemTwo.label'),
        content: t('home.faqs.items.itemTwo.content'),
        slot: 'item-2'
    },
    {
        label: t('home.faqs.items.itemThree.label'),
        content: t('home.faqs.items.itemThree.content'),
        slot: 'item-3'
    },
    {
        label: t('home.faqs.items.itemFour.label'),
        content: t('home.faqs.items.itemFour.content'),
        slot: 'item-4'
    },
];
</script>
<template>
    <div class="container py-10 md:py-14 px-5 mt-10">
        <div
            class="mt-12 text-center text-brand-secondary pb-6 font-extrabold text-xl leading-6 lg:text-3xl lg:leading-9">
            {{ t('home.faqs.title') }}
        </div>
        <UAccordion color="white" variant="ghost" size="xl" :items="items" open-icon="i-heroicons-plus"
            close-icon="i-heroicons-minus">
            <template #default="{ item, index, open }">
                <UButton color="white" variant="ghost"
                    class="transform text-base font-semibold text-brand-secondary px-0 text-left"
                    :ui="{ rounded: 'rounded-none', padding: { sm: 'py-3 px-7' } }">
                    <template #trailing>
                        <UIcon name="i-heroicons-chevron-right-20-solid"
                            class="ms-auto h-5 w-5 transform transition-transform duration-200"
                            :class="[open && 'rotate-90']" />
                    </template>
                    {{ item.label }}
                </UButton>
            </template>
            <template #item="{ item }">
                <p class="text-gray-900">
                    {{ item.content }}
                </p>
            </template>

            <template #item-1>
                <div class="text-gray-900">
                    {{ t('home.faqs.items.itemOne.entry') }}
                    <ul class="list-disc px-6 mt-2">
                        <li v-if="!authenticated">{{ t('home.faqs.items.itemOne.list.itemOne') }} <span
                                @click="store.open" class="underline cursor-pointer font-bold">{{
                t('home.faqs.items.itemOne.list.linkLabelOne') }}</span></li>
                        <li v-else>{{ t('home.faqs.items.itemOne.list.itemOne') }} <nuxt-link
                                to="/profile" class="underline font-bold">{{
                t('home.faqs.items.itemOne.list.linkLabelOne') }}</nuxt-link></li>
                        <li>{{ t('home.faqs.items.itemOne.list.itemTwo') }}
                            <nuxt-link :to="t('home.faqs.items.itemOne.list.linkTwo')" class="underline font-bold">{{
                t('home.faqs.items.itemOne.list.linkLabelTwo') }}</nuxt-link>
                        </li>
                    </ul>
                </div>
            </template>
            <template #item-2>
                <div class="text-gray-900">
                    <div class="text-gray-900">
                        {{ t('home.faqs.items.itemTwo.entry') }}
                        <ul class="list-disc px-6 mt-2">
                            <li v-if="!authenticated">{{ t('home.faqs.items.itemTwo.list.itemOne') }} <span
                                    @click="store.open" class="underline cursor-pointer font-bold">{{
                t('home.faqs.items.itemTwo.list.linkLabelOne') }}</span></li>
                            <li v-else>{{ t('home.faqs.items.itemTwo.list.itemOne') }} <nuxt-link
                                    to="/profile" class="underline cursor-pointer font-bold">{{
                t('home.faqs.items.itemTwo.list.linkLabelOne') }}</nuxt-link></li>
                            <li>{{ t('home.faqs.items.itemTwo.list.itemTwoPart1') }}
                                <nuxt-link :to="t('home.faqs.items.itemTwo.list.linkTwoPart1')" class="underline font-bold">{{
                t('home.faqs.items.itemTwo.list.linkLabelTwoPart1') }}</nuxt-link> {{
                t('home.faqs.items.itemTwo.list.itemTwoPart2') }}
                            </li>
                            <li>{{ t('home.faqs.items.itemTwo.list.itemThree') }}
                                <nuxt-link :to="t('home.faqs.items.itemTwo.list.linkThree')" class="underline font-bold">{{
                t('home.faqs.items.itemTwo.list.linkLabelThree') }}</nuxt-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
            <template #item-3>
                <div class="text-gray-900">
                    {{ t('home.faqs.items.itemThree.entry') }}
                    <ul class="list-disc px-6 my-2">
                        <li>{{ t('home.faqs.items.itemThree.list.itemOne') }} <nuxt-link
                                :to="t('home.faqs.items.itemThree.list.linkOne')" class="underline font-bold">{{
                t('home.faqs.items.itemThree.list.linkLabelOne') }}</nuxt-link></li>
                        <li>{{ t('home.faqs.items.itemThree.list.itemTwo') }}</li>
                    </ul>
                    {{ t('home.faqs.items.itemThree.closingNote') }}
                </div>
            </template>
            <template #item-4>
                <div class="text-gray-900">
                    {{ t('home.faqs.items.itemFour.content') }}
                    <nuxt-link :to="t('home.faqs.items.itemFour.linkOne')" class="underline font-bold">{{
                        t('home.faqs.items.itemFour.linkLabelOne') }}</nuxt-link>
                    {{ t('home.faqs.items.itemFour.closingNote') }}
                </div>
            </template>
        </UAccordion>
    </div>
</template>